import { showAlert,hideAlerts } from "./alert";


export const updateSettings =  async (data) => {
  try {
    const url = '/api/v1/users/updateMyPassword';

    const res = await axios({
      method: 'PATCH',
      url,
      data,
    });



    if(res.data.status === 'success'){
      showAlert('success',`تم تحديث كلمة السر بنجاح`);
      setTimeout(function(){
        hideAlerts();

      }, 3000)
    }

  }
  catch (err) {
    showAlert('error', err.response.data.message)
    setTimeout(function(){
      hideAlerts();

    }, 5000)
    console.log(err.response.data);
  }
}; 