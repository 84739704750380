
export const updateVisaStatus = async(data, id,url) => {
  try {

    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/clients/${id}`,
      data,
      headers: { "Content-Type": "multipart/form-data" },

    });
    if(res.data.status === 'success'){
        location.assign(url);
    }

  }
  catch (err) {
    console.log(err.response.data);
  }
}