export const printVisa = async (id) => {
  try {
    const response = await fetch(`/api/v1/checks/check-visa-status/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch visa status');
    }

    // Check if the response is a PDF file
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/pdf')) {
      // Get the filename from the content-disposition header or use a default
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
        : 'visa-status.pdf';

      // Convert response to blob
      const blob = await response.blob();
      
      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      
      // Trigger download
      document.body.appendChild(link);
      link.click();
      
    // Cleanup
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100);
      
      return true;
    } else {
      const data = await response.json();
      throw new Error(data.error || 'Invalid response format');
    }
  } catch (error) {
    console.error('Error downloading visa:', error);
    throw error;
  }
};

export const checkBatch = async (id) => {
  try {
    const response = await fetch(`/api/v1/checks/check-batch-status/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to check batch');
    }

    // Check if the response is a PDF file
  } catch (error) {
    console.error('Error downloading visa:', error);
    throw error;
  }
};

export const checkVisa = async (id) => {
  try {
    const response = await fetch(`/api/v1/checks/check-application-status/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to check visa');
    }

    // Check if the response is a PDF file
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkHos = async (id) => {
  try {
    const response = await fetch(`/api/v1/checks/check-hos-status/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to check visa');
    }

    // Check if the response is a PDF file
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const checkBatchHosFun = async (id) => {
  try {
    const response = await fetch(`/api/v1/checks/check-batch-hos/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to check batch');
    }

    // Check if the response is a PDF file
  } catch (error) {
    console.error('Error downloading visa:', error);
    throw error;
  }
};
