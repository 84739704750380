import catchAsync from "../../utlites/catchAsync";


export const createNewBatch =  async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/batches`,
      data,
    });

    if(res.data.status === 'success'){
        location.assign('/batches-list');
    }

  }
  catch (err) {
    console.log(err.response.data);
  }
}; 