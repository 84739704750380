/* eslint-disable */
import { updateClientData, createNewClient, deleteClient } from "./updateClientData";
import { updateVisaStatus } from "./updateVisaStatus";
import { createNewAgent } from "./createNewAgent";
import { createNewBatch } from "./createNewBatch";
import { login, logout, handleLogoutAfterInactivity } from "./login";
import { updateSettings } from "./updateUser";
import { createNewCareer } from "./addCareer";
import { moveBatch, updateBatch } from "./batchesFunctions";
import { updateMany } from "./updateMany";
import { printVisa,checkBatch,checkVisa,checkHos,checkBatchHosFun } from "./functions";

// handleLogoutAfterInactivity();

// Update last activity timestamp on user interaction
// function updateLastActivity() {
//   document.cookie = `lastActivity=${Date.now()};path=/;max-age=${15 * 60 * 1000}`;
// }

// // Add event listeners for user activity
// ['click', 'keypress', 'scroll', 'mousemove'].forEach(event => {
//   document.addEventListener(event, updateLastActivity);
// });

// // Check session status periodically
// setInterval(() => {
//   const lastActivity = parseInt(document.cookie.split('; ')
//     .find(row => row.startsWith('lastActivity'))
//     ?.split('=')[1] || '0');
  
//   const inactiveTime = Date.now() - lastActivity;
  
//   // If inactive for 15 minutes, redirect to login
//   if (inactiveTime > 15 * 60 * 1000) {
//     window.location.href = '/login';
//   }
// }, 60000); // Check every minute
//------------------Header-------------------------
const dropbtn = document.querySelector(".dropbtn");
if (dropbtn) {
  dropbtn.addEventListener("click", function (e) {
    document.getElementById("myDropdown").classList.toggle("show");
  });
}

window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};

const selectElementss = document.querySelectorAll("select");

selectElementss.forEach((select) => {
  if (!select.classList.contains("no-del-first")) {
    let options = select.getElementsByTagName("option");
    options[0].hidden = true;
  }
});

if (document.getElementById("logoutBtn")) {
  document.getElementById("logoutBtn").addEventListener("click", function (e) {
    e.preventDefault();
    logout();
  });
}

//---------------------------------------------------------

const feesPayedBtn = document.querySelectorAll(".feesPayed");
const passportActivatedBtn = document.querySelectorAll(".passportActivated");

const approvedBtn = document.querySelectorAll(".approvedBtn");
const rejectedBtn = document.querySelectorAll(".rejectedBtn");
const pendingBtn = document.querySelectorAll(".pendingBtn");
const grantBtn = document.querySelectorAll(".grantBtn");
const printVisaBtn = document.querySelectorAll(".printVisa");
const checkBatchBtn = document.getElementById("checkBatch");
const addAgentForm = document.getElementById("addAgentForm");
const checkAppBtn = document.querySelectorAll(".checkAppBtn");
const checkHosBtn = document.querySelectorAll(".checkHosBtn");
const checkBatchHos = document.getElementById("checkBatchHos");
const deleteClientBtn = document.querySelectorAll(".deleteClientBtn");
// const searchBtn = document.getElementById('search');

// searchBtn.addEventListener('click', function (e) {
//   e.preventDefault();
//   const clientName = document.getElementById('clientName').value;
//   const passportNumber = document.getElementById('passportNumber').value;
//   const agentName = document.getElementById('agentName').value;
//   const visaType = document.getElementById('visaType').value;
//   const applicationStuts = document.getElementById('applicationStuts').value;

//   window.location.href=`/clients?name=${clientName}&passportNumber=${passportNumber}&visaType=${visaType}&agentName=${agentName}`;
// })
// // ${query.name ? `&name=${query.name}`:''}${query.passportNumber ? `&passportNumber=${query.passportNumber}`:''}${query.visaType ? `&visaType=${query.visaType}`:''}${query.agentName ? `&agentName=${query.agentName}`:''}

const resetBtn = document.getElementById("reset");

if (resetBtn) {
  resetBtn.addEventListener("click", function (e) {
    e.preventDefault();
    window.location.href = `${window.location.pathname}`;
  });
}
// const form = document.getElementById('form');

// form.addEventListener('submit', function (e) {
//   // e.preventDefault();
// })

if (feesPayedBtn.length > 0) {
  feesPayedBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      const id = this.dataset.value;
      updateClientData({ paymentStatus: "مسدد" }, id, window.location.href);
    });
  });
}

if (printVisaBtn.length > 0) {

  printVisaBtn.forEach((btn) => {
    btn.addEventListener("click", async function (e) { 
      this.innerText = "جاري الطباعة"; 
      const id = this.dataset.value;
      try {
        await printVisa(id); // Use async/await for better control flow
      } catch (error) {
        console.error("Error printing visa:", error);
        this.innerText = "فشل الطباعة"; 
      } finally {
        this.innerText = "طباعة التأشيرة"; 
      }
    });
  });
}

if (checkAppBtn.length > 0) {
  console.log('checkAppBtn');
  checkAppBtn.forEach((btn) => {
    btn.addEventListener("click", async function (e) { 
      this.innerText = "جاري الاستعلام"; 
      const id = this.dataset.value;
      try {
        await checkVisa(id); // Use async/await for better control flow
      } catch (error) {
        console.error("Error printing visa:", error);
      } finally {
        this.innerText = "استعلام"; 
        window.location.reload();
      }
    });
  });
}

if (checkHosBtn.length > 0) {
  console.log('checkHosBtn');
  checkHosBtn.forEach((btn) => {
    btn.addEventListener("click", async function (e) { 
      this.innerText = "جاري الاستعلام"; 
      const id = this.dataset.value;
      try {
        await checkHos(id); // Use async/await for better control flow
      } catch (error) {
        console.error("Error printing visa:", error);
      } finally {
        this.innerText = "استعلام"; 
        window.location.reload();
      }
    });
  });
}


if (passportActivatedBtn.length > 0) {
  passportActivatedBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      const id = this.dataset.value;
      updateClientData({ paymentStatus: "مفعل" }, id, window.location.href);
    });
  });
}

if (approvedBtn.length > 0) {
  approvedBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      const id = this.dataset.value;
      updateVisaStatus({ visaStatus: "مؤشر" }, id, window.location.href);
    });
  });
}

if(deleteClientBtn.length > 0) {
  deleteClientBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      e.preventDefault();
      const id = this.dataset.value;
      deleteClient(id, window.location.href);
    });
  });
}

if (rejectedBtn.length > 0) {
  rejectedBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      const id = this.dataset.value;

      updateVisaStatus({ visaStatus: "مرتجع" }, id, window.location.href);
    });
  });
}

if (pendingBtn.length > 0) {
  pendingBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      const id = this.dataset.value;
      updateVisaStatus({ visaStatus: "قيد الطباعة" }, id, window.location.href);
    });
  });
}

if (grantBtn.length > 0) {
  grantBtn.forEach((btn) => {
    btn.addEventListener("click", function (e) {
      e.preventDefault();
      const id = this.dataset.value;
      let url = window.location.href;
      if (Array.from(btn.classList).includes("detailsPage")) {
        url = document.referrer;
      }
      updateVisaStatus({ applicationStatus: "مسلمة" }, id, url);
    });
  });
}
// Check and set initial button state
function initializeBatchButton(checkBatchBtn) {
  const batchState = localStorage.getItem('batchCheckState');
  if (batchState) {
    const { id, inProgress } = JSON.parse(batchState);
    if (inProgress && checkBatchBtn.dataset.value === id) {
      checkBatchBtn.innerText = "جاري تشييك الدفعة";
      checkBatchBtn.disabled = true;
    }
  }
}

// Update button state and localStorage
function updateBatchState(id, inProgress) {
  localStorage.setItem('batchCheckState', JSON.stringify({ id, inProgress }));
}

if (checkBatchBtn) {
  // Initialize button state on page load
  initializeBatchButton(checkBatchBtn);

  checkBatchBtn.addEventListener("click", async function (e) {
    console.log('checkBatchBtn');
    const id = this.dataset.value;
    
    try {
      this.innerText = "جاري تشييك الدفعة";
      this.disabled = true;
      updateBatchState(id, true);
      
      await checkBatch(id);
      
      // Clear state after successful completion
      updateBatchState(id, false);
    } catch (error) {
      console.error("Error checking batch:", error);
      this.innerText = "فشل التشييك";
      updateBatchState(id, false);
    } finally {
      this.disabled = false;
      this.innerText = "تشييك الدفعة";
    }
  });
}

function updateBatchCheck(id, inProgress) {
  localStorage.setItem('updateBatchCheck', JSON.stringify({ id, inProgress }));
}

if (checkBatchHos) {
  // Initialize button state on page load
  initializeBatchButton(checkBatchHos);

  checkBatchHos.addEventListener("click", async function (e) {
    console.log('checkBatchHos');
    const id = this.dataset.value;
    
    try {
      this.innerText = "جاري تشييك الدفعة";
      this.disabled = true;
      updateBatchCheck(id, true);
      
      await checkBatchHosFun(id);
      
      // Clear state after successful completion
      updateBatchCheck(id, false);
    } catch (error) {
      console.error("Error checking batch:", error);
      this.innerText = "فشل التشييك";
      updateBatchState(id, false);
    } finally {
      this.disabled = false;
      this.innerText = "تشييك الدفعة";
    }
  });
}
//-------------------- update password------------------------------------------
const updatePasswordForm = document.getElementById("resetPasswordForm");
const updatePassBtn = document.querySelector(".updatePassBtn");
if (updatePasswordForm) {
  updatePasswordForm.addEventListener("submit", async (e) => {
    e.preventDefault();
    updatePassBtn.innerHTML = "جاري تحديث كلمة السر";
    const password = document.getElementById("password-current").value;
    const newPassword = document.getElementById("password").value;
    const newPasswordConfirm =
      document.getElementById("password-confirm").value;

    await updateSettings(
      { password, newPassword, newPasswordConfirm },
      "password"
    );
    updatePassBtn.innerHTML = "تغيير كلمة السر";
  });
}

//------------------ADD AGENT PAGE----------------------------------------------
const wakilForm = document.getElementById("wakilForm");

if (wakilForm) {
  wakilForm.addEventListener("submit", function (event) {
    event.preventDefault();
    validateInputs();
  });
}

function validateInputs() {
  const name = document.getElementById("agent_name").value.trim();
  const username = document.getElementById("username").value.trim();
  const password = document.getElementById("password").value.trim();
  const passwordConfirm = document.getElementById("password2").value.trim();
  const phone = document.getElementById("phone_number").value.trim();
  const branch = document.getElementById("agent_branch").value;
  const workVisaCommission = document
    .getElementById("occupation_fee")
    .value.trim();
  const visitVisaCommission = document.getElementById("visit_fee").value.trim();

  let isValid = true;

  if (name === "") {
    setError(document.getElementById("agent_name"), "يرجى ادخال الاسم");
    isValid = false;
  } else {
    setSuccess(document.getElementById("agent_name"));
  }

  if (username === "") {
    setError(document.getElementById("username"), "يرجى ادخال الاسم");
    isValid = false;
  } else {
    setSuccess(document.getElementById("username"));
  }

  if (password === "") {
    setError(document.getElementById("password"), "يرجى ادخال كلمة المرور");
    isValid = false;
  } else {
    setSuccess(document.getElementById("password"));
  }

  if (passwordConfirm === "") {
    setError(document.getElementById("password2"), "يرجى تأكيد كلمة المرور");
    isValid = false;
  } else if (password != passwordConfirm) {
    setError(document.getElementById("password2"), "كلمة المرور غير متطابقة");
  } else {
    setSuccess(document.getElementById("password2"));
  }

  if (phone === "") {
    setError(document.getElementById("phone_number"), "يرجى ادخال رقم الهاتف");
    isValid = false;
  } else {
    setSuccess(document.getElementById("phone_number"));
  }
  if (branch === "") {
    setError(document.getElementById("agent_branch"), "يرجى اختيار المحافظة");
    isValid = false;
  } else {
    setSuccess(document.getElementById("agent_branch"));
  }

  if (workVisaCommission === "") {
    setError(
      document.getElementById("occupation_fee"),
      "يرجى ادخال عمولة العمل"
    );
    isValid = false;
  } else {
    setSuccess(document.getElementById("occupation_fee"));
  }

  if (visitVisaCommission === "") {
    setError(document.getElementById("visit_fee"), "يرجى ادخال عمولة الزيارة");
    isValid = false;
  } else {
    setSuccess(document.getElementById("visit_fee"));
  }
  const role = "Agent";
  if (isValid) {
    createNewAgent({
      name,
      username,
      password,
      passwordConfirm,
      phone,
      branch,
      role,
      workVisaCommission,
      visitVisaCommission,
    });
  }
}

//---------------------------------------------------------------
let popup = document.getElementById("popup");

const createBatch = document.getElementById("createNewBatch");

function openPopup() {
  popup.classList.add("open-popup");
}
if (createBatch) {
  createBatch.addEventListener("click", function (e) {
    e.preventDefault();
    openPopup();
  });
}
function closePopup() {
  popup.classList.remove("open-popup");
}

// creating new batch

const createBatchBtn = document.getElementById("add");
if (createBatchBtn) {
  createBatchBtn.addEventListener("click", function (e) {
    e.preventDefault();
    const batchNumber = document.getElementById("batchNumberCreate").value;
    const batchName = document.getElementById("batchNameCreate").value;
    const transportCompany = document.getElementById("transportCompany").value;
    const branch = user.branch;
    createNewBatch({ batchNumber, batchName, transportCompany, branch });
  });
}

const loginForm = document.querySelector(".login-form");

if (loginForm) {
  loginForm.addEventListener("submit", function (e) {
    e.preventDefault();

    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    login(username, password);
  });
}

//-----------------------CUSTOMER DETAILES-------------------------------------------
const customerForm = document.getElementById("CustomerForm");
const applicationPrintBtn = document.getElementById("applicationPrintBtn");
let visaTypeSelect = document.getElementById("visa_type");
const hospitalDev = document.querySelectorAll(".hospitalDev");
const checkDateInput = document.getElementById("check_date");
const saveClient = document.getElementById("saveClient");


if (customerForm) {

  async function checkPassportNumber(ppNumber) {
    try {
      const response = await fetch(`/api/v1/clients/check-passport/${ppNumber}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error checking passport number:', error);
      return { exists: false };
    }
  }
  
  let ppNumberInput = document.querySelector(".pass_number");
if(ppNumberInput){
  ppNumberInput.addEventListener('blur', async function() {
    const ppNumber = this.value;
    if (ppNumber) {      
      const result = await checkPassportNumber(ppNumber);
      if (result.exists) {
        const clientInfo = result.clientInfo;
        const message = `رقم الجواز موجود بالفعل للعميل:
        الاسم: ${clientInfo.name}
        رقم الطلب: ${clientInfo.applicationNumber || 'غير متوفر'}
        
        هل تريد المتابعة ؟`;
        
        const confirmSave = confirm(message);
        if (!confirmSave) {
          ppNumberInput.value = '';
          ppNumberInput.focus();
        }
      }
    }
  });
}
  customerForm.addEventListener("submit", function (event) {
    event.preventDefault();
    validateClientsInputs();
    // addingCustomerForm.submit();
  });
  document.addEventListener("DOMContentLoaded", () => {
    visaTypeSelect.addEventListener("change", function (e) {
      hospitalDev.forEach((el) => {
        const isVisible = !(
          visaTypeSelect.value === "عمل" || visaTypeSelect.value === "اقامة"
        );
        el.hidden = isVisible;
      });
    });

    // Initial check when the page loads
    const initialVisibility = !(
      visaTypeSelect.value === "عمل" || visaTypeSelect.value === "اقامة"
    );
    hospitalDev.forEach((el) => {
      el.hidden = initialVisibility;
    });
  });
}
if (applicationPrintBtn) {
  applicationPrintBtn.addEventListener("click", function (event) {
    window.location.href = `/client/details/application/${applicationPrintBtn.value}`;
  });
}

const validateClientsInputs = async () => {
  const arNameInput = document.getElementById("ar_name");
  const phoneNumberInput = document.getElementById("phone_number");
  const genderSelect = document.getElementById("gender");
  const personalPhotoInput = document.getElementById("personal_photo");
  const visaNumberInput = document.getElementById("visa_number");
  const recordNumberInput = document.getElementById("record_number");
  const occupationSelect = document.getElementById("occupation");
  const occupation = document.getElementById("occupationOptionsSearch");
  const agentSelect = document.getElementById("agent");
  const batchNumberSelect = document.getElementById("batch_number");
  const notedsInput = document.getElementById("notes");
  const puyerInput = document.getElementById("puyer");
console.log(document.getElementById("puyer"))
  const documentSelectAll = Array.from(
    document.querySelectorAll(".attactmentsClassDetailsPage")
  );
  const shortageSelectAll = Array.from(
    document.querySelectorAll(".shortageClassDetailsPage")
  );
  const sealSelectAll = Array.from(
    document.querySelectorAll(".stampsClassDetailsPage")
  );

  // const attachmentsNamesArray = attachments.map((attachment) => attachment.name);
  let ppNumberInput = document.getElementById("pp_number");
  const issuePlaceInput = document.getElementById("issue_place");
  const birthPlaceInput = document.getElementById("birth_place");
  const birthDateInput = document.getElementById("birth_date");
  const issueDateInput = document.getElementById("issue_date");
  const expDateInput = document.getElementById("exp_date");
  const ppPhotoInput = document.getElementById("pp_photo");
  const orderNumberInput = document.getElementById("order_number");
  const enNameInput = document.getElementById("en_name");
  const transactionFeeInput = document.getElementById("transaction_fee");
  const insuranceFeeInput = document.getElementById("insurance_fee");
  const hospitalSelect = document.getElementById("hospital");

  const khtabNumSelect = document.getElementById("khtabNum");
  const khtabDateSelect = document.getElementById("khtabDate");

  const applicant = document.getElementById("applicant");
  const arrivalPoint = document.getElementById("arrival-point");

  const notes = notedsInput.value;
  const name = arNameInput.value.trim();
  const phone = phoneNumberInput.value.trim();
  const gender = genderSelect.value;
  let picture = personalPhotoInput.files[0];
  const visaNumber = visaNumberInput.value.trim();
  const commercialRegister = recordNumberInput.value.trim();
  const visaType = visaTypeSelect.value;
  let career = occupationSelect.value;
  const agent = agentSelect.dataset.agentValue;
  const hospital = hospitalSelect.value;
  const ExaminationDate = checkDateInput.value;
  const batch = batchNumberSelect.value;
  const passportNumber = ppNumberInput.value.trim();
  const issuingAuthority = issuePlaceInput.value.trim();
  const placeOfBirth = birthPlaceInput.value.trim();
  const dateOfBirth = birthDateInput.value;
  const dateOfIssue = issueDateInput.value;
  const dateOfExpiry = expDateInput.value;
  let passportPic = ppPhotoInput.files[0];
  const applicationNumber = orderNumberInput.value.trim();
  const englishName = enNameInput.value.trim();
  const fees = transactionFeeInput.value.trim();
  const insurance = insuranceFeeInput.value.trim();

  const comingFrom = arrivalPoint.value;
  const requestedBy = applicant.value;

  const attachments = attachmentsfun(documentSelectAll);
  const shortcomings = attachmentsfun(shortageSelectAll);
  const stamps = attachmentsfun(sealSelectAll);

  const pictureOfProfile = document.querySelector(".profilePic");
  const pictureOfPassport = document.querySelector(".passportPic");

  let isValid = true;



  if (occupation.value != "" && career === "") {
    isValid = await createNewCareer({ name: occupation.value });
    career = occupation.value;
    occupation.value = "";
  }

  if (name === "") {
    setError(arNameInput, "يرجى ادخال الاسم");
    isValid = false;
  } else {
    setSuccess(arNameInput);
  }

  if (phone === "") {
    setError(phoneNumberInput, "يرجى ادخال رقم الهاتف");
    isValid = false;
  } else {
    setSuccess(phoneNumberInput);
  }

  if (gender === "") {
    setError(genderSelect, "يرجى اختيار الجنس");
    isValid = false;
  } else {
    setSuccess(genderSelect);
  }

  // if (visaNumber === '') {
  //     setError(visaNumberInput, "يرجى ادخال رقم التأشيرة");
  // isValid = false;
  // } else {
  //     setSuccess(visaNumberInput);
  // }

  // if (commercialRegister === '') {
  //     setError(recordNumberInput, "يرجى ادخال رقم السجل");
  // isValid = false;
  // } else {
  //     setSuccess(recordNumberInput);
  // }

  if (visaType === "") {
    setError(visaTypeSelect, "يرجى اختيار نوع التأشيرة");
    isValid = false;
  } else {
    setSuccess(visaTypeSelect);
  }

  // if (career === "") {
  //   setError(occupationSelect, "يرجى اختيار المهنة");
  //   isValid = false;
  // } else {
  //   setSuccess(occupationSelect);
  // }

  if (agent === "" && agentSelect.value === "") {
    setError(agentSelect, "يرجى اختيار الوكيل");
    isValid = false;
  } else {
    setSuccess(agentSelect);
  }

  if (batch === "") {
    setError(batchNumberSelect, "يرجى اختيار رقم الدفعة");
    isValid = false;
  } else {
    setSuccess(batchNumberSelect);
  }

  if (passportNumber === "") {
    setError(ppNumberInput, "يرجى ادخال رقم الجواز");
    isValid = false;
  } else {
    setSuccess(ppNumberInput);
  }

  if (dateOfIssue === "") {
    setError(issuePlaceInput, "يرجى ادخال مكان الاصدار");
    isValid = false;
  } else {
    setSuccess(issuePlaceInput);
  }

  if (issuingAuthority === "") {
    setError(birthPlaceInput, "يرجى ادخال محل الميلاد");
    isValid = false;
  } else {
    setSuccess(birthPlaceInput);
  }

  if (dateOfBirth === "") {
    setError(birthDateInput, "يرجى ادخال تاريخ الميلاد");
    isValid = false;
  } else {
    setSuccess(birthDateInput);
  }

  if (placeOfBirth === "") {
    setError(birthPlaceInput, "يرجى ادخال تاريخ الميلاد");
    isValid = false;
  } else {
    setSuccess(birthPlaceInput);
  }

  if (dateOfIssue === "") {
    setError(issueDateInput, "يرجى ادخال تاريخ الاصدار");
    isValid = false;
  } else {
    setSuccess(issueDateInput);
  }

  if (dateOfExpiry === "") {
    setError(expDateInput, "يرجى ادخال تاريخ الانتهاء");
    isValid = false;
  } else {
    setSuccess(expDateInput);
  }

  if (!picture && !pictureOfProfile) {
    setError(personalPhotoInput, "يرجى اختيار صورة شخصية");
    isValid = false;
  } else if (!picture && pictureOfProfile) {
    picture = pictureOfProfile.src.split("/")[5];
  } else {
    setSuccess(personalPhotoInput);
  }

  if (!passportPic && !pictureOfPassport) {
    setError(ppPhotoInput, "يرجى اختيار صورة الجواز");
    isValid = false;
  } else if (!passportPic && pictureOfPassport) {
    passportPic = pictureOfPassport.src.split("/")[5];
  } else {
    setSuccess(ppPhotoInput);
  }
  if(!shortcomings.includes("انتظار نتيجة الفحص")){
  if (hospital === "" && hospitalDev[1].hidden === false) {
    setError(hospitalSelect, "يرجى اختيار المستشفى");
    isValid = false;
  } else {
  }

  if (hospital === "" && hospitalDev[2].hidden === false) {
    setError(checkDateInput, "يرجى ادخال تاريخ الفحص");
    isValid = false;
  } else {
  }
}else {
  setSuccess(hospitalSelect);
  setSuccess(checkDateInput);

}

  // if (applicationNumber === '') {
  //     setError(orderNumberInput, "يرجى ادخال رقم الطلب");
  //     isValid = false;
  // } else {
  //     setSuccess(orderNumberInput);
  // }

  // if (englishName === '') {
  //     setError(enNameInput, "يرجى ادخال الاسم بالانجليزي");
  //     isValid = false;
  // } else {
  //     setSuccess(enNameInput);
  // }

  // if (fees === "") {
  //   setError(transactionFeeInput, "يرجى ادخال رسوم المعاملة");
  //   isValid = false;
  // } else {
  //   setSuccess(transactionFeeInput);
  // }

  // if (insurance === "") {
  //   setError(insuranceFeeInput, "يرجى ادخال رسوم التأمين");
  //   isValid = false;
  // } else {
  //   setSuccess(insuranceFeeInput);
  // }

  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const clientId = pathSegments[pathSegments.length - 1];
  const branch = user.branch;

  const formData = new FormData();
  const passportData = {
    passportNumber: passportNumber,
    issuingAuthority: issuingAuthority,
    placeOfBirth: placeOfBirth,
    dateOfBirth: dateOfBirth,
    dateOfIssue: dateOfIssue,
    dateOfExpiry: dateOfExpiry,
    passportPicture: passportPic,
  };
  formData.append("name", name);
  formData.append("englishName", englishName);
  formData.append("phone", phone);
  formData.append("picture", picture);
  formData.append("gender", gender);
  formData.append("branch", branch);
  formData.append("shortcomings", JSON.stringify(shortcomings));
  formData.append("stamps", JSON.stringify(stamps));
  formData.append("visaNumber", visaNumber);
  formData.append("attachments", JSON.stringify(attachments));
  formData.append("commercialRegister", commercialRegister);
  formData.append("applicationNumber", applicationNumber);
  formData.append("visaType", visaType);
  formData.append("agent", agent);
  formData.append("batch", batch);
  formData.append("fees", fees);
  formData.append("insurance", insurance);
  formData.append("passport", JSON.stringify(passportData));
  formData.append("requestedBy", requestedBy);
  formData.append("comingFrom", comingFrom);
  formData.append("notes", notes);
  formData.append("passportPicture", passportPic);
  formData.append("khtabNum", khtabNumSelect.value);
  formData.append("khtabDate", khtabDateSelect.value);

  if (hospitalDev[0].hidden === false) {
    formData.append("career", career);
  }
  if (hospitalDev[1].hidden === false) {
    formData.append("hospital", hospital);
  }
  if (hospitalDev[2].hidden === false && ExaminationDate != "") {
    formData.append("ExaminationDate", ExaminationDate);
  }
console.log(puyerInput)
  if(puyerInput.value.trim() != "") {
    formData.append("puyer", puyerInput.value.trim());

  }
  // formData.append(`passport.passportPicture`, passportPic);
  if (isValid && customerForm.classList.contains("updateCustomerFrom")) {
    saveClient.innerHTML = "جاري حفط التغيرات";
    updateClientData(formData, clientId, document.referrer);
  } else if (isValid && customerForm.classList.contains("addCustomerFrom")) {
    saveClient.innerHTML = "جاري اضافة العميل";
    createNewClient(formData, document.referrer);
  }
  console.log()

  saveClient.innerHTML = "حفظ البيانات";
  return isValid;
};

const setError = (element, message) => {
  const inputControl = element.parentElement;
  const errorDisplay = inputControl.querySelector(".form-error");
  errorDisplay.innerText = message;
  element.classList.add("is-invalid");
};

const setSuccess = (element) => {
  const inputControl = element.parentElement;
  const errorDisplay = inputControl.querySelector(".form-error");

  errorDisplay.innerText = "";
  element.classList.remove("is-invalid");
};

function attachmentsfun(array) {
  const x = array.map((el) => {
    return el.value;
  });
  return x;
}

//----------------------------طباعة خطاب--------------------------
const khatbBtn = document.getElementById("printKtab");

if (khatbBtn) {
  const khtabNumSelect = document.getElementById("khtabNum");
  const khtabDateSelect = document.getElementById("khtabDate");
  const arNameInput = document.getElementById("ar_name");
  const occupationSelect = document.getElementById("occupation");
  const ppNumberInput = document.getElementById("pp_number");
  const orderNumberInput = document.getElementById("order_number");
  khatbBtn.addEventListener("click", function (e) {
    khatbBtn.innerContect = "جاري التحميل";
    fetch("/api/v1/docs/download-hospital-reports", {
      method: "POST",
      url: "/api/v1/docs/download-hospital-reports",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: arNameInput.value,
        passport: ppNumberInput.value,
        appnum: orderNumberInput.value,
        occup: occupationSelect.value,
        date: khtabDateSelect.value,
        khtabNum: khtabNumSelect.value
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Assuming the server sends the correct Content-Type
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url; 
      
        a.download = `خطاب رقم ${khtabNumSelect.value} - ${arNameInput.value}.docx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error, e.g., display an error message to the user
      });
      khatbBtn.innerHTML = "تحميل الملف";

  });
}

//-----------------------------طباعة سند----------------------------
const receiptBtn = document.getElementById("receiptBtn");
if (receiptBtn) {
  const arNameInput = document.getElementById("ar_name");
  const puyerselect = document.getElementById("puyer").value.trim()? document.getElementById("puyer").value.trim(): arNameInput.value;
  const url = `/api/v1/docs/download-visit-receipt`
  receiptBtn.addEventListener("click", function (e) {
    receiptBtn.innerContect = "جاري التحميل";
    fetch(url, {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: arNameInput.value,
        puyer: puyerselect,
        visa: document.getElementById("visa_type").value,
        receiptNum
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Assuming the server sends the correct Content-Type
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url; 
      
        a.download = `سند-${arNameInput.value}.docx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error, e.g., display an error message to the user
      });
      receiptBtn.innerHTML = "تحميل الملف";

  });
}


//-------------------------------------------

const moveBtn = document.querySelector('.button-move-batch');
const batchForm = document.querySelector('.updateBatch');
const closeBatchBtn = document.querySelector('.closeBatchBtn');

if (moveBtn) {
  moveBtn.addEventListener('click',function(e){
    const oldBatch = `${window.location.href}`.split('/').pop(); 
    const newBatch = document.querySelector('#moveTobatchNumber').value;
    moveBatch(oldBatch, newBatch)
  })
}

if(batchForm){
  console.log('sdd')

  batchForm.addEventListener('click', function(e){
    console.log('sdd')
    const batchNumber = document.querySelector('#batchNumber').value;
    const batchName = document.querySelector('#batchName').value;
    const location = document.querySelector('#location').value;
    const id = `${window.location.href}`.split('/').pop(); 
    updateBatch({ batchName: batchName, location: location,batchNumber: batchNumber},id)
  })
}
if(closeBatchBtn){
  closeBatchBtn.addEventListener('click', function(e){
    const id = this.dataset.value;
    updateBatch({ status: 'مغلقة'},id)
  })
}


//---------------------------------------
function manageDropDown() {
  document.getElementById("manageDropDown").classList.toggle("show");
}
if(document.querySelector('.manageDropbtn')){


document.querySelector('.manageDropbtn').addEventListener('click',manageDropDown);

window.onclick = function (event) {
  if (!event.target.matches('.dropbtn') && !event.target.matches('.manageDropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
          }
      }
  }
}
}
//--------------------------------update Many-----------------------------
const selectAllCheckbox = document.getElementById('selectAll')
const rowCheckboxes = document.querySelectorAll('.rowCheckbox')
if(selectAllCheckbox){
  selectAllCheckbox.addEventListener('change', (event) => {
    rowCheckboxes.forEach(checkbox => checkbox.checked = event.target.checked)
  })
}

const grantManyBtn = document.getElementById('grantManyButton');
if (grantManyBtn){
  document.getElementById('grantManyButton').addEventListener('click', function(event) {
    updateMany('/api/v1/clients/updateMany',rowCheckboxes, "مسلمة");
  });
}

const approvedBtnAll = document.querySelector('.approvedBtnAll');

if(approvedBtnAll){
  approvedBtnAll.addEventListener('click', function(event) {
    updateMany('/api/v1/clients/updateMany',rowCheckboxes, "مؤشر");
  });
}

const rejectedBtnAll = document.querySelector('.rejectedBtnAll' ,);

if(rejectedBtnAll){
  rejectedBtnAll.addEventListener('click', function(event) {
    updateMany('/api/v1/clients/updateMany',rowCheckboxes, "مرتجع");
  });
}

const pendingBtnAll = document.querySelector('.pendingBtnAll');

if(pendingBtnAll){
  pendingBtnAll.addEventListener('click', function(event) {
    updateMany('/api/v1/clients/updateMany',rowCheckboxes, "قيد الطباعة");
  });
}

const feesPayedAll = document.querySelector('.feesPayedAll');

if(feesPayedAll){
  feesPayedAll.addEventListener('click', function(event) {
    updateMany('/api/v1/clients/updateMany',rowCheckboxes, "مسدد");
  });
}

const passportActivatedAll = document.querySelector('.passportActivatedAll');

if(passportActivatedAll){
  passportActivatedAll.addEventListener('click', function(event) {
    updateMany('/api/v1/clients/updateMany',rowCheckboxes, "مفعل");
  });
}



//-------------------------scrasping

// if(document.getElementById('scrapBtn')){


// document.getElementById('scrapBtn').addEventListener('submit', async (e) => {
//     e.preventDefault();
//     // const result = document.getElementById('result');
    
//     try {
//         const response = await fetch('/check-visa-status', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 searchingType: "sddsd",
//                 applicationNumber: "asdd",
//                 sponserId: "dsd"
//             })
//         });

//         const data = await response.json();
//         result.className = data.success ? 'success' : 'error';
//         result.textContent = data.message || JSON.stringify(data);
//     } catch (error) {
//         result.className = 'error';
//         result.textContent = 'Error: ' + error.message;
//     }
// });
// }
