import catchAsync from "../../utlites/catchAsync";
import { showAlert, hideAlerts } from "./alert";

export const updateClientData = async (data, id, url) => {
  const submitButton = document.getElementById('saveClient'); 

  try {
    if(submitButton){
    submitButton.disabled = true;
    }
     const res = await axios({
      method: "PATCH",
      url: `/api/v1/clients/${id}`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (res.data.status === "success") {
      location.assign(url);
    }
  } catch (err) {
    submitButton.disabled = false;
    showAlert('error', err.response.data.message)
    setTimeout(function(){
      hideAlerts();

    }, 5000)
  }
};

export const createNewClient = async (data, url) => {
  const submitButton = document.getElementById('saveClient'); // Assuming you have a submit button

  try {
    submitButton.disabled = true;

    const res = await axios({
      method: "POST",
      url: `/api/v1/clients`,
      data,
      headers: { "Content-Type": "multipart/form-data" },

    });

    if (res.data.status === "success") {
      location.assign(url);

    }
  } catch (err) {
    submitButton.disabled = false;
    showAlert('error', err.response.data.message)
    setTimeout(function(){
      hideAlerts();

    }, 5000)

  }
};


export const deleteClient = async (id, url) => {
  console.log(`deleting ${id}`)
  try {
    const res = await axios({
      method: "DELETE",
      url: `/api/v1/clients/${id}`,
    });
    console.log(res)
    if (res.status == 204) {

      location.reload();

    }

  } catch (err) {
    showAlert('error', err.response.data.message)
    setTimeout(function(){
      hideAlerts();

    }, 5000)

  }

}