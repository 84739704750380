import { hideAlerts, showAlert } from "./alert";

export const createNewAgent =  async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/agents`,
      data,
    });
    if(res.data.status === 'success'){
      showAlert('success', 'لقد تم انشاء الوكيل بنجاح');
      setTimeout(function(){
        hideAlerts();
        location.assign('/agents');

      }, 3000)
    }

  }
  catch (err) {
    showAlert('error', err.response.data.message)
    setTimeout(function(){
      hideAlerts();
    }, 5000)
  }
}; 