import { showAlert, hideAlerts } from "./alert";

export const moveBatch = async (oldBatch, newBatch)=>{
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/batches/${oldBatch}/move`,
      data: {
        newBatch: newBatch
      }
    });
    if(res.data.status ==='success'){
      showAlert('success', 'لقد تم نقل جميع الجوازات الغير مكتملة بنجاح');
      setTimeout(() => {
        hideAlerts();
      }, 3000)
    }
  } catch (error) {
    showAlert('error', 'لقد فشلت عملية النقل');
  }
}

export const updateBatch = async (data,id)=>{
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/batches/${id}`,
      data
    });
    if(res.data.status ==='success'){
      showAlert('success', 'لقد تم تحديث بيانات الدفعة');
      setTimeout(() => {
        hideAlerts();
      }, 3000)
    }
  } catch (error) {
    showAlert('error', 'فشل في تحديث بيانات الدفعة');
  }
}