import { hideAlerts, showAlert } from "./alert";


export const login = async (username, password) => {

  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        username,
        password
      }
    })

    if(res.data.status === 'success'){
      showAlert('success', 'تم تسجيل الدخول')
      setTimeout(() => {
        location.assign('/clients')
      }, 500)
    }
  } catch (err) {
    showAlert('error', 'فشل تسجيل الدخول يرجى التحقق من اسم المستخدم او كملة السر');

  }
}

export const logout = async () =>{

  try {

  const res = await axios({
    method: 'GET',
    url: '/api/v1/users/logout'
  });
  if(res.data.status === 'success') location.assign('/login');;
  } catch (err) {
    showAlert('error', 'فشل تسجيل الدخول');
  }
}


// Function to handle user logout after inactivity
export const handleLogoutAfterInactivity= (expiryTimeInMs = 60*60*1000) => { // Default to 1 hour (1 hour * 60 minutes * 60 seconds * 1000 milliseconds)
  let inactivityTimeout = setTimeout(() => {
    const res = axios({
      method: 'GET',
      url: '/api/v1/users/logout'
    }).then(resposne => {
      // Handle logout response here
      console.log('User inactive for too long, logging out...');
      location.assign('/login'); // Uncomment this line to redirect to login page 
    }).catch(err => {
      console.error('Failed to log out user:', err);
    })
  }, expiryTimeInMs);

  // Reset the timeout on user activity (e.g., mouse move, key press)
  document.addEventListener('mousemove', resetTimeout);
  document.addEventListener('keypress', resetTimeout);

  function resetTimeout() {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(() => {
      // Logout after renewed inactivity
      handleLogoutAfterInactivity(expiryTimeInMs);
    }, expiryTimeInMs);
  }
}
