
import { showAlert, hideAlerts } from "./alert";
export const createNewCareer =  async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/careers`,
      data,
    });

  }
  catch (err) {
    showAlert('error', err.response.data.message)
    setTimeout(function(){
      hideAlerts();

    }, 4000)

    return false
  }
  return true;
}; 