import { showAlert  } from "./alert"

export const updateMany = async (url, rowCheckboxes, status)=>{

    const selectedRows = []
    rowCheckboxes.forEach((checkbox, index) => {
      console.log(checkbox.value)
      if (checkbox.checked) {
        selectedRows.push(checkbox.value)
      }
    })
    if (selectedRows.length === 0) {
      alert('Please select rows to export!')
      return
    }
  
    const res = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idsToUpdate: selectedRows,
        status:status
      }),

    });
  
    try {
      const data = await res.json();
      if (data.status === 'success') {
        showAlert('success', `تم تحديث حالة جميع الجوازات الى ${status}`);
        setTimeout(() => {
        location.reload(); 
        }, 2000);
      } else {
        // Handle error response from the server
        console.error('Error updating clients:', data);
        showAlert('error', 'حدث خطأ أثناء التحديث'); // Example error message
      }
    } catch (error) {
      // Handle network errors or parsing issues
      console.error('Error fetching data:', error);
      showAlert('error', 'حدث خطأ أثناء الاتصال بالخادم'); // Example error message
    }
  

    const data = JSON.stringify(selectedRows);
  
}


