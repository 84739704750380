export const hideAlerts = () =>
  {
    const el = document.querySelector('.alert');
    if(el) el.parentElement.removeChild(el);
  
  }
  
  export const showAlert = (status, message) => {
    hideAlerts();
    const markUp = `<div class="alert alert--${status}">${message}</div>`
    document.querySelector('body').insertAdjacentHTML('afterbegin', markUp);
  
  }